import "./home.css";
import React, { useEffect, useState } from "react";
import { useSpring, animated, config } from "react-spring";
import { css } from "@emotion/react";
import { BeatLoader } from "react-spinners";
import Footer from "./footer";
import Appbar from "./appbar";
import Icon from "./icon";
import Cont from "./cont";
import Application from "./app";
import { animateScroll as scroll, Events, scrollSpy } from "react-scroll";
import { useNavigate } from "react-router-dom";





export default function Home(){

  const imageUrls = ["Image1.png", "Image2.png", "Image3.png", "Image4.png", "Image5.png"];
  const img =["img4.png","img3.png","img2.png","img1.png"];
  const cardsData = [
    { text: "Access your complete transaction history in one place. Stay organized and never lose track of your purchases again", image: "phone.png", heading: "Organized Transaction History" },
    { text: "Snap, save, done! Effortlessly digitize your receipts with our intuitive capture feature – no more manual entry", image: "phone2.png", heading: "Seamless Receipt Capture" },
    { text: "For businesses, enjoy cost savings and streamlined operations. Our platform provides a hassle-free solution for managing customer transactions", image: "phone3.png", heading: "Various payment options" },
  ];
  
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate(); 

  const [guideProps, setGuideProps] = useSpring(() => ({
    opacity: 0,
    transform: "translateX(50px)",
    config: config.molasses,
  }));
  const [guideProps2, setGuideProps2] = useSpring(() => ({
    opacity: 0,
    transform: "translateX(50px)",
    config: config.molasses,
  }));
  const [guideProps3, setGuideProps3] = useSpring(() => ({
    opacity: 0,
    transform: "translateX(50px)",
    config: config.molasses,
  }));
  const [guideProps4, setGuideProps4] = useSpring(() => ({
    opacity: 0,
    transform: "translateX(50px)",
    config: config.molasses,
  }));
  const [guideProps5, setGuideProps5] = useSpring(() => ({
    opacity: 0,
    transform: "translateX(50px)",
    config: config.molasses,
  }));
  const [guideProps6, setGuideProps6] = useSpring(() => ({
    opacity: 0,
    transform: "translateX(50px)",
    config: config.molasses,
  }));

  useEffect(() => {
    Events.scrollEvent.register("begin", function (to, element) {});

    Events.scrollEvent.register("end", function (to, element) {});

    scrollSpy.update();

    window.addEventListener("scroll", handleScroll);

    return () => {
      Events.scrollEvent.remove("begin");
      Events.scrollEvent.remove("end");
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    Events.scrollEvent.register("begin", function (to, element) {});

    Events.scrollEvent.register("end", function (to, element) {});

    scrollSpy.update();

    window.addEventListener("scroll", handleScroll2);

    return () => {
      Events.scrollEvent.remove("begin");
      Events.scrollEvent.remove("end");
      window.removeEventListener("scroll", handleScroll2);
    };
  }, []);
  useEffect(() => {
    Events.scrollEvent.register("begin", function (to, element) {});

    Events.scrollEvent.register("end", function (to, element) {});

    scrollSpy.update();

    window.addEventListener("scroll", handleScroll3);

    return () => {
      Events.scrollEvent.remove("begin");
      Events.scrollEvent.remove("end");
      window.removeEventListener("scroll", handleScroll3);
    };
  }, []);
  useEffect(() => {
    Events.scrollEvent.register("begin", function (to, element) {});

    Events.scrollEvent.register("end", function (to, element) {});

    scrollSpy.update();

    window.addEventListener("scroll", handleScroll4);

    return () => {
      Events.scrollEvent.remove("begin");
      Events.scrollEvent.remove("end");
      window.removeEventListener("scroll", handleScroll4);
    };
  }, []);
  useEffect(() => {
    Events.scrollEvent.register("begin", function (to, element) {});

    Events.scrollEvent.register("end", function (to, element) {});

    scrollSpy.update();

    window.addEventListener("scroll", handleScroll5);

    return () => {
      Events.scrollEvent.remove("begin");
      Events.scrollEvent.remove("end");
      window.removeEventListener("scroll", handleScroll5);
    };
  }, []);
  useEffect(() => {
    Events.scrollEvent.register("begin", function (to, element) {});

    Events.scrollEvent.register("end", function (to, element) {});

    scrollSpy.update();

    window.addEventListener("scroll", handleScroll6);

    return () => {
      Events.scrollEvent.remove("begin");
      Events.scrollEvent.remove("end");
      window.removeEventListener("scroll", handleScroll6);
    };
  }, []);

  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  const handleScroll = () => {
    const scrollY = window.scrollY;

    if (scrollY > 1000) {
      setShowScrollButton(true);
      setGuideProps({
        opacity: 1,
        transform: "translateX(0)",
      });
    } else {
      setShowScrollButton(false);
      setGuideProps({
        opacity: 0,
        transform: "translateX(-100px)",
      });
    }
  };
  const handleScroll2 = () => {
    const scrollY = window.scrollY;

    if (scrollY > 1500) {
      setShowScrollButton(true);
      setGuideProps2({
        opacity: 1,
        transform: "translateX(0)",
      });
    } else {
      setShowScrollButton(false);
      setGuideProps2({
        opacity: 0,
        transform: "translateX(100px)",
      });
    }
  };

  const handleScroll3 = () => {
    const scrollY = window.scrollY;

    if (scrollY > 1500) {
      setShowScrollButton(true);
      setGuideProps3({
        opacity: 1,
        transform: "translateY(0)",
      });
    } else {
      setShowScrollButton(false);
      setGuideProps3({
        opacity: 0,
        transform: "translateY(-100px)",
      });
    }
  };
  const handleScroll4 = () => {
    const scrollY = window.scrollY;

    if (scrollY > 1000) {
      setShowScrollButton(true);
      setGuideProps4({
        opacity: 1,
        transform: "translateX(0)",
      });
    } else {
      setShowScrollButton(false);
      setGuideProps4({
        opacity: 0,
        transform: "translateX(100px)",
      });
    }
  };
  const handleScroll5 = () => {
    const scrollY = window.scrollY;

    if (scrollY > 1800) {
      setShowScrollButton(true);
      setGuideProps5({
        opacity: 1,
        transform: "translateY(0)",
      });
    } else {
      setShowScrollButton(false);
      setGuideProps5({
        opacity: 0,
        transform: "translateY(-100px)",
      });
    }
  };
  const handleScroll6 = () => {
    const scrollY = window.scrollY;

    if (scrollY > 1800) {
      setShowScrollButton(true);
      setGuideProps6({
        opacity: 1,
        transform: "translateX(0)",
      });
    } else {
      setShowScrollButton(false);
      setGuideProps6({
        opacity: 0,
        transform: "translateX(100px)",
      });
    }
  };
 const buttonClick = () =>{
  navigate('/register');
 }
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return (
      <div className="loading-spinner">
        <BeatLoader color="#36D7B7" loading={loading} size={15} />
      </div>
    );
  }
    return(
        <>
     <div className="main-div">
    <div className="homee">
    <Appbar/>
      <div className="homee-margin">
      <div className="main-home">
            <p>
            Green Transactions: Say Hello to
            </p>
        </div>
       
       <div className="digtal-main">
       <div className="shadodw">
       <center> <div className="digital">
                <p>
                    Digital Receipts
                </p>
            </div></center>
        </div>
       </div>
       <div className="home-text">
        <div className="text">
          <p>
          Digitize your receipts effortlessly with our user-friendly mobile app and website. Store, organize, and manage your transactions all in one secure place
          </p>
        </div>
       </div>
       <div className="green">
        <button onClick={buttonClick}>
          <img src="Layer.png" alt="" />
          Go green
        </button>
       </div>
       <div className="ratings">
        <div className="rating-pics">
          {}
          {imageUrls.map((imageUrl, index) => (
            <div key={index} className="pics">
              <img src={imageUrl} alt={`Rating ${index + 1}`} />
            </div>
          ))}
        </div>
        <div className="starts">
      <div className="star-box">
        <img src="stars.png" alt="" /><img src="stars.png" alt="" /><img src="stars.png" alt="" /><img src="stars.png" alt="" />
      </div>
   <div className="stars-text">
          <p>
          Trusted by 400+ customers
          </p>
        </div>
        </div>
      </div>
    <div className="head-pics">
      {img.map((img,index)=>(
         <div key={index} className="imgs">
         <img src={img} alt="" />
    </div>
      )
      )}
    </div>
    <div className="head">
      <div className="head-head">
        <p>
        Experience the Future with our App
        </p>
      </div>
      <div className="head-p">
        <p>
        Navigate the future of receipt management with ease – our app seamlessly integrates powerful features for effortless receipt capture, organized history, and eco-friendly storage
        </p>
      </div>
    </div>
  
    <>
  {cardsData.map((cards, index) => (
    <div key={index} className="cards-home">
      {index === 1 ? (
        <>
          <div className="card-2">
            <animated.div style={guideProps3} className="phone-text2">
              <div className="phone-cont2">
                <div className="phone-head">
                  <p>{cards.heading}</p>
                </div>
                <div className="phone-textt">
                  <p>{cards.text}</p>
                </div>
              </div>
            </animated.div>

            <animated.div style={guideProps2} className="phone2">
              <div className="iphone">
                <img src={cards.image} alt="" />
              </div>
            </animated.div>
          </div>
        </>
      ) : index === 2 ? (
        <>
          <animated.div style={guideProps5} className="phone">
            <div className="iphone">
              <img src={cards.image} alt="" />
            </div>
          </animated.div>
          <div className="phone-text">
            <animated.div style={guideProps6} className="phone-cont">
              <div className="phone-head">
                <p>{cards.heading}</p>
              </div>
              <div className="phone-textt">
                <p>{cards.text}</p>
              </div>
            </animated.div>
          </div>
        </>
      ) : (
        <>
          <animated.div style={guideProps} className="phone">
            <div className="iphone">
              <img src={cards.image} alt="" />
            </div>
          </animated.div>
          <div className="phone-text">
            <animated.div style={guideProps4} className="phone-cont">
              <div className="phone-head">
                <p>{cards.heading}</p>
              </div>
              <div className="phone-textt">
                <p>{cards.text}</p>
              </div>
            </animated.div>
          </div>
        </>
      )}
    </div>
  ))}
</>

    <div className="head" style={{marginTop:"50px",marginBottom:"30px"}}>
      <div className="head-head">
        <p>
        Why go paperless?
        </p>
      </div>
      <div className="head-p">
        <p>
        Opt out of paper receipts and enjoy having all your eReceipts in one single digital account
                </p>
      </div>
    </div>
  <Icon/>
<div className="home-cont-div">
<Cont/>
</div>
      </div>
 <div className="home-footer">
 <Footer/>
 </div>
 
    </div>
     </div>
     <div
        className={`scroll-to-top ${showScrollButton ? "show" : ""}`}
        onClick={scrollToTop}
      >
        Scroll to Top
      </div>
     
        </>
        
    )
}