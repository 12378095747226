import React, { useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import './home.css'; // Import your CSS file

export default function Cont() {
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: '-100px 0px', // Adjust this value based on when you want the animation to start
  });

  const animationClass = inView ? 'animate' : '';

  return (
    <>
      <div className={`contact-home ${animationClass}`} ref={ref}>
        <div className="head-head">
          <p>Don’t hear from us</p>
        </div>
      </div>
      <div className={`contact ${animationClass}`} ref={ref}>
        <div className="cont-text">
          <div className="contact-text">
            <img src="cots.png" alt="" />
            <p>
              Life-changing app! The convenience of snapping receipts and having them organized effortlessly has saved me so much time. The eco-friendly approach aligns perfectly with my values
            </p>
            <img src="cross.png" alt="" />
          </div>
        </div>
        <div className="cont-img">
          <div className="contact-img">
            <img src="contact.png" alt="" />
          </div>
        </div>
      </div>
    </>
  );
}
