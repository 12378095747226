import Appbar from "./appbar";
import "./about.css"
import Application from "./app";
import Footer from "./footer";
import { BeatLoader } from "react-spinners";
import React, { useEffect, useState } from "react";
import "../components/partner.css"


export default function About(){
  const [loading, setLoading] = useState(true);

  const team =[
    {img:"team1.svg", name:"Joe Rogan", title:"Founder"},
    {img:"team2.svg", name:"Larry James", title:"Co-founder"},
    {img:"team3.svg", name:"Jack Theobald", title:"CTO"},
    {img:"team4.svg", name:"Sarika Jain", title:"Design lead"},
    {img:"team5.svg", name:"James Will", title:"Engineering lead"},
    {img:"team6.svg", name:"Mary Cruz", title:"Customer support manager"},
  ];
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return (
      <div className="loading-spinner">
        <BeatLoader color="#36D7B7" loading={loading} size={15} />
      </div>
    );
  }
    return(
        <> 
        <div className="about-main">
            <div className="about">
                <Appbar/>
               <div className="about-shadow">
               <div className="partners-text">
                    <div className="partner-shadow-text">
                      <div className="ppp">
                      <p>
                      Redefining  
                        </p>
                      </div>
                        <div className="partner-shadow">
                            <div className="p-shadow-text">
                                <p>Receipts</p>
                            </div>
                        </div>
                        <div className="ppp">
                      <p>
                      for Digital  
                        </p>
                      </div>
                    </div>
                    <div className="about-head">
                      <p>
                        Tomorrow
                      </p>
                    </div>
                        <div className="about-para">
                            <p>
                            At eRexeipt, our mission is clear - to provide a revolutionary digital platform that simplifies receipt management. For retailers, our goal is to enhance operational efficiency, reduce costs, and contribute to a greener planet
                            </p>
                        </div>
                       
                </div>
               </div>
                <div className="about-img">
                  <div className="about-pic">
                    <img src="about.svg" alt="" />
                  </div>
                </div>
                <div className="about-mission">
                  <div className="about-miss">
                    <div className="about-textt">
                      <div className="mission-head">
                        <p>
                          Our Mission
                        </p>
                      </div>
                      <div className="mission-text">
                        <p>
                        Our mission is to reinvent the shopping experience and eliminate paper waste with a true paperless receipt system
                        </p>
                      </div>
                      <div className="mission-flex">
                        <div className="mission-member">
                         <div className="mem">
                         <p >
                            6+
                          </p>
                         </div>
                          <p>
                            Team members
                          </p>
                        </div>
                        <div className="mission-member">
                         <div className="mem">
                         <p>
                            $200K
                          </p>
                         </div>
                          <p>
                            Funding so far
                          </p>
                        </div>
                       
                      </div>

                    </div>
                    <div className="about-missimg">
                    <div className="mission-picture">
                      <img src="mission.svg" alt="" />
                    </div>
                    </div>
                  </div>
                </div>
                <div className="head">
      <div className="head-head">
        <p>
        Meet the team
        </p>
      </div>
      <div className="head-p">
        <p>
        We are proud to introduce the creative minds behind our commitment to transforming the way receipts are managed
        </p>
      </div>
    </div>
  <div className="team-cont">
  <div className="team">
      {team.map((map,index)=>
      <div key={index} className="team-pic">
        <div className="team-profile">
          <div className="team-profile-pic">
            <img src={map.img} alt="" />
          </div>
          <div className="team-name">
            <p>
              {map.name}
            </p>
          </div>
          <div className="team-title">
            <p>
              {map.title}
            </p>
          </div>
        </div>

      </div>
      )}
    </div>
  </div>
       <div className="about-app">
       {/* <Application/> */}
       </div>
       <Footer/>
            </div>
        </div>
        </>
    )
}