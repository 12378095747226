import "./home.css";
import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function Appbar() {
  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef(null);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const closeMenu = () => {
    setShowMenu(false);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      closeMenu();
    }
  };

  useEffect(() => {
    // Add event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="appbar">
        <div className="bar-size">
          <div className="logo">
            <img src="logoo.svg" alt="" />
          </div>
          
          <div className="mobile-menu" onClick={toggleMenu}>
            <span>&#9776;</span>
          </div>
          <div className={`bar ${showMenu ? "show-menu" : ""}`} ref={menuRef}>
           <Link to="/retailer" style={{color:"black"}}>
           <Button variant="transparent" onClick={closeMenu}>
              Reatiler
            </Button>
           </Link>

          <Link to="/contact" style={{color:"black"}}>
          <Button variant="transparent" onClick={closeMenu}>
              Contact
            </Button>
          </Link>

            <Link to="/guide"style={{color:"black"}}>
              <Button variant="transparent" onClick={closeMenu}>
                Guide
              </Button>
            </Link>

            {showMenu && (
             <Link to="/Register">
              <Button className="sign-button-app">
                Sign in
              </Button>
             </Link>
            )}
            {showMenu && (
              <Link to="/Register">
                <Button className="sign-button-app">
                Register
              </Button>
              </Link>
            )}
          </div>
          <div className="sign-home">
            <Link to="/Register">
              <button>Sign In</button>
            </Link>
          </div>
        </div>
      </div>
      {/* Conditionally render "Sign In" button in the dropdown */}
      {showMenu && (
        <div className="dropdown-menu">
          <Button variant="transparent" onClick={closeMenu}>
            Sign In
          </Button>
        </div>
      )}
    </>
  );
}
