import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import 'firebase/firestore'; 
import { getFirestore, updateDoc } from 'firebase/firestore'; 
import { getStorage, uploadBytes } from 'firebase/storage'; 
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { doc, deleteDoc } from "firebase/firestore";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCLqucYpcuspIn8pd6qj75m698iAFMtPX0",
  authDomain: "erecxpit.firebaseapp.com",
  projectId: "erecxpit",
  storageBucket: "erecxpit.appspot.com",
  messagingSenderId: "894610239473",
  appId: "1:894610239473:web:5edb15e1af97b97942de50",
  measurementId: "G-3SV8WF0CYL"
};


const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const firestore = getFirestore(app); 
const storage = getStorage(app);

export {
  app,
  analytics,
  auth,
  firestore,
  storage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  doc,
  deleteDoc,
  uploadBytes,
  updateDoc
};
