import React, { useState } from "react";
import "./sign.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link, useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword, getAuth } from "@firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { app, auth, firestore } from "../firebase";
import { ToastContainer, toast } from "react-toastify";

const cardsData = [
  { name: "Jason Bell", image: "Image1.png", title: "Shopper", text: "1receipt is an innovative and out of the box idea to keep record of receipts digital. Good for environment and user friendly platform with ample of marketing opportunities”" },
  { name: "Mohmin", image: "Image2.png", title: "Shopper", text: "1receipt is an innovative and out of the box idea to keep record of receipts digital. Good for environment and user friendly platform with ample of marketing opportunities”" },
  { name: "Mohmin", image: "Image4.png", title: "Shopper", text: "1receipt is an innovative and out of the box idea to keep record of receipts digital. Good for environment and user friendly platform with ample of marketing opportunities”" },
];

export default function Register() {
  const [activeButton, setActiveButton] = useState(1);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [ownerName, setOwnerName] = useState(''); 
  const [phoneNumber, setPhoneNumber] = useState(''); 
  const [abnNumber, setAbnNumber] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [businessLocation, setBusinessLocation] = useState('');
  const [businessCategory, setBusinessCategory] = useState('');
  const [partnerName, setPartnerName] = useState('');
  const navigate = useNavigate();
  const auth = getAuth(app);
  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    dots: true,
  };

  const toggleDiv = (divNumber) => {
    setActiveButton(divNumber);
  };

  const handleSignup = async (e) => {
    e.preventDefault();
  
    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }
  
    setLoading(true);
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
  
      if (activeButton === 1) {
        // Store user details
        await setDoc(doc(firestore, "users", user.uid), {
          ownerName: ownerName,
          email: email,
          phoneNumber: phoneNumber,
        });
      } else if (activeButton === 2) {
        // Store business details in a separate collection
        await setDoc(doc(firestore, "businesses", user.uid), {
          email: email,
          abnNumber: abnNumber,
          businessName: businessName,
          businessLocation: businessLocation,
          businessCategory: businessCategory,
          partnerName: partnerName,
        });
      }
  
      navigate('/signin');
      toast.success('Registered Succefully ')
    } catch (error) {
      toast.error(error.message);
    }
  
    setLoading(false);
  };
  

  return (
    <>
    <ToastContainer/>
      <div className="main-container">
        <div className="main">
          <div className="main2">
            <p className="text">
              Seamless <br /> Transition <br /> From Paper To <br /> Digital
            </p>
            <p className="text2">
              Helping paperless shoppers save 9 million <br /> trees a year with
              one digital receipt app.
            </p>
            <div className="card-cont">
              <center>
                <Slider {...settings}>
                  {cardsData.map((card, index) => (
                    <div key={index} className="card1">
                      <div className="card-message">
                        <p className="massege">
                          {card.text}
                        </p>
                      </div>
                      <div className="card-title">
                        <img src={card.image} alt={card.title} />
                        <div className="profile">
                          <h2 className="profile-name">{card.name}</h2>
                          <h3 className="profile-name">{card.title}</h3>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </center>
            </div>
          </div>
          <div className="main4" >
            <div className="Register" >
              <p className="sign-in">Register</p>
              <p>Enter Your Personal And Business Details</p>
              <div className="rr-button">
                <button
                  className={`rbutton ${activeButton === 1 ? "active" : ""}`}
                  onClick={() => toggleDiv(1)}
                >
                  Profile Details
                </button>
                <button
                  className={`rbutton ${activeButton === 2 ? "active" : ""}`}
                  onClick={() => toggleDiv(2)}
                >
                  Business Details
                </button>
              </div>

              {activeButton === 1 ? (
                <div className="div1">
                  {/* Content for the first div */}
                  <div className="register-f">
                    <div className="fields">
                      <input type="text" placeholder="Owner name*" value={ownerName}
                        onChange={(e) => setOwnerName(e.target.value)} />
                      <input type="email" placeholder="Email*" value={email}
                        onChange={(e) => setEmail(e.target.value)} />
                      <input type="number" placeholder="Phone number*" value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)} />
                      <input type="password" placeholder="Password*" value={password}
                        onChange={(e) => setPassword(e.target.value)} />
                      <input type="password" placeholder="Confirm password*" value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)} />
                    </div>
                  </div>

                  <button className="Login-button" onClick={handleSignup}>
                    Next
                  </button>
                  <div className="register-but" >
                    <Link to="/signin">
                      <button className="already">
                        Already have an account?<strong style={{ paddingLeft: "3px" }}>Login</strong>
                      </button>
                    </Link>
                  </div>
                </div>
              ) : (
                <div className="div2">
                  <div className="register-f">
                    <div className="fields">
                      <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                      <input type="text" placeholder="ABN number*" value={abnNumber} onChange={(e) => setAbnNumber(e.target.value)} />
                      <input type="text" placeholder="Business name*" value={businessName} onChange={(e) => setBusinessName(e.target.value)} />
                      <input type="text" placeholder="Business location*" value={businessLocation} onChange={(e) => setBusinessLocation(e.target.value)} />
                      <input type="text" placeholder="Select category*" value={businessCategory} onChange={(e) => setBusinessCategory(e.target.value)} />
                      <input type="text" placeholder="Partner name*" value={partnerName} onChange={(e) => setPartnerName(e.target.value)} />
                      <input type="password" placeholder="Password*" value={password} onChange={(e) => setPassword(e.target.value)} />
                      <input type="password" placeholder="Confirm password*" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                    </div>
                  </div>

                  <button className="Login-button" onClick={handleSignup}>
                    Register
                  </button>
                  <div className="register-but">
                    <Link to="/signin">
                      <button className="already">
                        Already have an account?<strong style={{ paddingLeft: "3px" }}>Login</strong>
                      </button>
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
