import Application from "./app";
import Appbar from "./appbar";
import Cont from "./cont";
import "./faq.css"
import React, { useState } from 'react';
import Footer from "./footer";import "./contactus.css"


export default function Contact(){
    const [expandedIndices, setExpandedIndices] = useState([]);

    const toggleFaq = (index) => {
        if (expandedIndices.includes(index)) {
          setExpandedIndices(expandedIndices.filter((i) => i !== index));
        } else {
          setExpandedIndices([...expandedIndices, index]);
        }
      };
    
    const faq = [
        {text:"How do I download the shopper app?",text2:"eRexeipt app can be downloaded via App store & Google play store"},
        {text:"I'm a retailer. How can I get 1receipt for my store?",text2:"eRexeipt app can be downloaded via App store & Google play store"},
        {text:"How does the receipt capture feature work?",text2:"eRexeipt app can be downloaded via App store & Google play store"},
        {text:"Is the app secure for storing sensitive financial information?",text2:"eRexeipt app can be downloaded via App store & Google play store"},
        {text:"Can I access my receipts from multiple devices?",text2:"eRexeipt app can be downloaded via App store & Google play store"},
        {text:"How does the app benefit retailers?",text2:"eRexeipt app can be downloaded via App store & Google play store"},
        {text:"Are there any eco-friendly features in the app?",text2:"eRexeipt app can be downloaded via App store & Google play store"},
        {text:"I'm a retailer. Can I experience a live demo of ereceipt?",text2:"eRexeipt app can be downloaded via App store & Google play store"},
        {text:"Can I still get my receipt via 1receipt if I'm paying by cash?",text2:"eRexeipt app can be downloaded via App store & Google play store"},

    ];
    return(
        <>
        <div className="contact-us-main">
            <div className="contact-us">
                <Appbar/>
               <div className="contactt-mid">
               <div className="contactt">
                    <div className="contactt-text">
                        <div className="conatctt-head">
                            <p>
                            We’d love to
                            </p>
                            <div className="contactt-shadow">
                                <div className="contactt-shadow-text">
                                    <p> hear </p>
                                </div>
                            </div>
                        </div>
                        <div className="contactt-head2">
                            <p>
                                from you
                            </p>
                        </div>
                        <div className="contactt-para">
                        <p>
                        We value your feedback and are here to assist you. Whether you have questions, suggestions, or need support, our team is ready to help.
                        </p>
                    </div>
                    <div className="contactt-flex">
                        <div className="contactt-phone">
                            <div className="contactt-icon">
                                <img src="phonee.svg" alt="" />
                                <p>
                                03 9028 8298
                                </p>
                            </div>
                            <div className="contactt-icon">
                                <img src="email.svg" alt="" />
                                <p>
                                info@erexeipts.com
                                </p>
                            </div>
                        </div>
                    </div>

                    </div>
                   
                    <div className="contactt-img">
                        <div className="contactt-pic">
                            <img src="contactt.svg" alt="" />
                        </div>
                    </div>
                </div>
               </div>

               <div className="faq-head">
                   <div className="faqss">
                   <p>
                    FAQ’s
                    </p>
                   </div>
                    <div className="faq-content">
      {faq.map((faqs, index) => (
        <div key={index} className="faqs-cont">
          <div className="faqs-container">
            <div className="faq-main-text">
              <p>{faqs.text}</p>
            </div>
            <div className="faq-child-text" style={{ display: expandedIndices.includes(index) ? 'block' : 'none' }}>
              <p>{faqs.text2}</p>
            </div>
          </div>
          <div className="faq-icon">
            <button onClick={() => toggleFaq(index)}>
              {expandedIndices.includes(index) ? '-' : '+'}
            </button>
          </div>
        </div>
      ))}
    </div>
                </div>
                <Footer/>
            </div>
        </div>
        </>
    )
}