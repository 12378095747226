import React, { useEffect, useState } from "react";
import { animateScroll as scroll, Events, scrollSpy } from "react-scroll";
import Appbar from "./appbar";
import Cont from "./cont";
import Application from "./app";
import Footer from "./footer";
import "./guide.css";
import { useSpring, animated, config } from "react-spring";
import { BeatLoader } from "react-spinners";

export default function Guide() {
  const [loading, setLoading] = useState(true);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [guideProps, setGuideProps] = useSpring(() => ({
    opacity: 0,
    transform: "translateY(50px)",
    config: config.molasses,
  }));

  useEffect(() => {
    Events.scrollEvent.register("begin", function (to, element) {});

    Events.scrollEvent.register("end", function (to, element) {});

    scrollSpy.update();

    window.addEventListener("scroll", handleScroll);

    return () => {
      Events.scrollEvent.remove("begin");
      Events.scrollEvent.remove("end");
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  const handleScroll = () => {
    const scrollY = window.scrollY;

    if (scrollY > 100) {
      setShowScrollButton(true);
      setGuideProps({
        opacity: 1,
        transform: "translateY(0)",
      });
    } else {
      setShowScrollButton(false);
      setGuideProps({
        opacity: 0,
        transform: "translateY(50px)",
      });
    }
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return (
      <div className="loading-spinner">
        <BeatLoader color="#36D7B7" loading={loading} size={15} />
      </div>
    );
  }

  return (
    <>
      <div className="guide-home" style={guideProps}>
        <div className="guide-homee">
          <Appbar />
          <div className="guidee-main">
            <div className="guidee-text">
              <div className="guidee-head">
                <div className="guidee-tilt">
                  <div className="guidee-shadow">
                    <p>Your</p>
                    <div className="guidee-box">
                      <div className="guidee-boxx">
                        <p>guide</p>
                      </div>
                    </div>
                    <p>to</p>
                  </div>
                </div>
                <p>eRexeipt</p>
              </div>
              <div className="guidee-card">
                <div className="guidee-steps">
                  <p className="g-step">Step 1</p>
                  <p>Download the app</p>
                </div>
                <div className="guidee-steps">
                  <p className="g-step"> Step 2</p>
                  <p>Start shopping</p>
                </div>
                <div className="guidee-steps">
                  <p className="g-step">Step 3</p>
                  <p>Scan the barcode</p>
                </div>
                <div className="guidee-steps">
                  <p className="g-step">Step 4</p>
                  <p>Receive instant receipt</p>
                </div>
                <div className="guidee-steps">
                  <p className="g-step">Step 5</p>
                  <p>Enjoy your paper-free shopping</p>
                </div>
              </div>
            </div>
            <div className="guidee-img">
              <div className="guidee-image">
                <img src="guide.png" alt="" />
              </div>
            </div>
          </div>
          <div className="guidee-contact">
            <Cont />
            {/* <Application /> */}
          </div>
          <div className="guidee-footer">
            <Footer />
          </div>
        </div>
      </div>
      {/* Add a button to scroll to top */}
      <div
        className={`scroll-to-top ${showScrollButton ? "show" : ""}`}
        onClick={scrollToTop}
      >
        Scroll to Top
      </div>
    </>
  );
}
