import { BrowserRouter, Routes,Route } from 'react-router-dom';
import './App.css';
import Signin from './login/sign-in';
import Register from './login/Register';
import Home from './components/homepage';
import Retail from './components/retailer';
import Pos from './components/pos';
import Guide from './components/guide';
import Partner from './components/partner';
import Faq from './components/faq';
import Feature from './components/features';
import About from './components/about';
import Contact from './components/contactus';
import Privacy from './components/privacy';
import TC from './components/t&c';
import { signInWithPhoneNumber } from '@firebase/auth';

function App() {
 return(
  <BrowserRouter>
  <Routes>
    <Route path='/' element={<Home/>}/>
  <Route path='/signin' element={<Signin/>}/>
  <Route path='/Register' element={<Register/>}/>
  <Route path='/retailer' element={<Retail/>}/>
  <Route path='/POS' element={<Pos/>}/>
  <Route path='/guide' element={<Guide/>}/>
  {/* <Route path='/partner' element={<Partner/>}/> */}
  <Route path='/FAQ' element={<Faq/>}/>
  <Route path='/feature' element={<Feature/>}/>
  <Route path='/about' element={<About/>}/>
  <Route path='/contact' element={<Contact/>}/>
  <Route path='/privacy' element={<Privacy/>}/>
  <Route path='/T&C' element={<TC/>}/>
  </Routes>
  </BrowserRouter>
 )
}

export default App;
