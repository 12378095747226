

export default function Icon(){
    const list = [
        {text:"Contactless receipts",icon:"icon1.png",detail:"Instant smart receipt collection with one quick scan at checkout"},
        {text:"Access anywhere",icon:"Frame2.png",detail:"Access all your electronic receipts from any device any anywhere"},
        {text:"Digital search",icon:"Frame3.png",detail:"Organize expenses with a user-friendly search, sort and filter"},
      ];
      const list2 = [
        {text:"Reward hub",icon:"Frame4.png",detail:"Say no to plastic by storing your rewards cards in-app"},
        {text:"Cybersecured",icon:"Frame5.png",detail:"End to end data encryption so you’re protected before, during and after shopping"},
        {text:"Apple & google wallet",icon:"Frame6.png",detail:"Easily integrate your in-app barcode with your phone wallet for faster checkout"},
      ]
    return(
        <>
         <div className="icon-main1">
   {list.map((icon, index) => (
  <div key={index} className="icons-home">
    <div className="icon-main">
      <div className="icon">
        <img src={icon.icon} alt="" />
      </div>
      <div className="icon-head">
        <p>{icon.text}</p>
      </div>
      <div className="icon-text">
        <p>{icon.detail}</p>
      </div>
    </div>
  </div>
))}
   </div>
   <div className="icon-main1">
   {list2.map((icon2, index) => (
  <div key={index} className="icons-home">
    <div className="icon-main">
      <div className="icon">
        <img src={icon2.icon} alt="" />
      </div>
      <div className="icon-head">
        <p>{icon2.text}</p>
      </div>
      <div className="icon-text">
        <p>{icon2.detail}</p>
      </div>
    </div>
  </div>
))}
   </div>
        </>
    )
}