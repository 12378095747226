import Appbar from "./appbar";
import "./pos.css"
import Cont from "./cont";
import Footer from "./footer";
import { BeatLoader } from "react-spinners";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { animateScroll as scroll, Events, scrollSpy } from "react-scroll";



export default function Pos(){
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return (
      <div className="loading-spinner">
        <BeatLoader color="#36D7B7" loading={loading} size={15} />
      </div>
    );
  }
  const scrollToTop = () => {
    scroll.scrollToTop();
  };
    return(
        <>
     <div className="poss-main">
     <div className="poss">
      <Appbar/>
      <div className="pos-home">
        <div className="pos-text">
            <p>
              Streamline
            </p>
            <div className="pos-shadow">
             <div className="shadow-pos">
             <div className="transactions">
               <p>
                Transactions
               </p>
              </div>
             </div>
              <div className="at">
                <p>at</p>
              </div>
            </div>
            <p>Point of Sale</p>
            <div className="pos-para">
              <p>
              Elevate your Point of Sale (POS) experience with our cutting-edge digital receipt solution
              </p>
            </div>
            <div className="pos-home-button">
              <Link onClick={scrollToTop} to="/contact" >
              <button>
              Contact now
              </button>
              </Link>
             
            </div>
        </div>
        <div className="pos-img">
          <div className="pos-home-img">
               <img src="image18.png" alt="" />
          </div>
        </div>
      </div>
      <div className="pos-integration">
      <div className="head">
      <div className="head-head">
        <p>
        Integrating with eRexeipt means
        </p>
      </div>
        <div className="pos-icons">
          <div className="pos-int">
            <img src="reve.svg" alt="" />
            <p>Extra revenue</p>
          </div>
          <div className="pos-int">
            <img src="enviornment.svg" alt="" />
            <p>Helping environment</p>
          </div>
          <div className="pos-int">
            <img src="receipt.svg" alt="" />
            <p>Digital receipt</p>
          </div>
        </div>
    </div>
      </div>
       
      <div className="pos-contact">
      <Cont/>
      </div>
      <div className="pos-int-api">
      <div className="head">
      <div className="head-head">
        <p>
        Integration via eRexeipt API
        </p>
      </div>
        <div className="pos-icons">
          <div className="pos-int">
           <p className="api">1</p>
            <p>Extra revenue</p>
          </div>
          <div className="pos-int">
            <p className="api">2</p>
            <p>Helping environment</p>
          </div>
          <div className="pos-int">
           <p className="api">3</p>
            <p>Digital receipt</p>
          </div>
        </div>
    </div>
      </div>
      {/* <div className="head">
      <div className="head-head">
        <p>
        Join the program now
        </p>
      </div>
      <div className="head-p">
        <p>
        Opt out of paper receipts and enjoy having all your eReceipts in one single digital account
        </p>
      </div>
      <button className="apply">
         Apply now
      </button>
    </div> */}
       <div className="pos-footer">
       <Footer/>
       </div>
      </div>
     </div>
     
        </>
    )
}