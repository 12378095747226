import Appbar from "./appbar";
import Footer from "./footer";
import "./privacy.css";
import { Link } from "react-router-dom";


export default function Privacy(){
    const privacy = [
        {title:"Information we collect", text:"We collect information necessary for the effective operation of our services. This may include personal details, transaction data, and device information. Rest assured, we only collect data essential for providing and improving our services"},
        {title:"Use of information", text:"Your data is used to enhance your user experience, personalize content, and improve our products and services. We prioritize the security of your information and do not sell or share it with third parties for marketing purposes"},
        {title:"Data security", text:"We implement robust security measures to protect your information from unauthorized access, disclosure, alteration, and destruction. Our encryption protocols and secure storage practices ensure the confidentiality of your data"},
        {title:"Cookies", text:"Our website and app may use cookies and similar technologies to enhance your browsing experience. You have the option to control cookie preferences, but please note that restricting cookies may impact the functionality of our services"},
        {title:"Third-party links", text:"Our platform may contain links to third-party websites. We are not responsible for the privacy practices or content of these external sites. We recommend reviewing the privacy policies of any linked websites"},
    ]
    return(
        <>
        <div className="privacy-main">
            <div className="privacy">
                <Appbar/>
                <div className="privacy-head">
                    <div className="privacy-heading">
                        <p>
                        Our Privacy Policy
                        </p>
                    </div>
                    <div className="privacy-text">
                        <p>
                        At eRexeipt, we take your privacy seriously and are committed to protecting the personal information you share with us. Our Privacy Policy outlines how we collect, use, and safeguard your data to ensure a secure and transparent experience
                        </p>
                    </div>
                </div>
               <div className="policy-cardss">
               <div className="policy">
                    {privacy.map((privacy,index)=>
                    <div key={index} className="privacy-card">
                          <div className="privacy-card-head">
                            <p>
                            {privacy.title}
                            </p>
                          </div>
                          <div className="privacy-card-text">
                            <p>
                                {privacy.text}
                            </p>
                          </div>
                    </div>
                    )}
                </div>
               </div>
               <div className="policy-cardss">
               <div className="policy">
                   
                    <div className="privacy-card">
                          <div className="privacy-card-head">
                            <p>
                            Policyupdates
                            </p>
                          </div>
                          <div className="privacy-card-text">
                            <p>
                            We may update our Privacy Policy to reflect changes in our practices or legal requirements. Any updates will be communicated to you, and your continued use of our services implies acceptance of the revised terms. By using eRexeipt's services, you agree to the terms outlined in this Privacy Policy. If you have any questions or concerns, please contact our Privacy Team at <a href="mailto:info@erexeipts.com" style={{color:"rgba(75, 85, 99, 1)"}}>info@erexeipts.com</a>.Thank you for entrusting us with your data. Your privacy is our priority.
                            </p>
                          </div>
                    </div>
                
                </div>
               </div>
                      <Footer/>
            </div>
        </div>
        </>
    )
}