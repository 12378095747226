import "./footer.css";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useSpring, config } from "react-spring";
import { animateScroll as scroll, Events, scrollSpy } from "react-scroll";
import { auth, firestore } from "../firebase"; // Importing auth and firestore from your firebase.js file
import { doc, setDoc } from "firebase/firestore";

export default function Footer() {
  const [showContactDialog, setShowContactDialog] = useState(false);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [guideProps, setGuideProps] = useSpring(() => ({
    opacity: 0,
    transform: "translateY(50px)",
    config: config.molasses,
  }));

  useEffect(() => {
    Events.scrollEvent.register("begin", function (to, element) {});

    Events.scrollEvent.register("end", function (to, element) {});

    scrollSpy.update();

    window.addEventListener("scroll", handleScroll);

    return () => {
      Events.scrollEvent.remove("begin");
      Events.scrollEvent.remove("end");
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  const handleScroll = () => {
    const scrollY = window.scrollY;

    if (scrollY > 1000) {
      setShowScrollButton(true);
      setGuideProps({
        opacity: 1,
        transform: "translateY(0)",
      });
    } else {
      setShowScrollButton(false);
      setGuideProps({
        opacity: 0,
        transform: "translateY(50px)",
      });
    }
  };

  const handleContactClick = () => {
    setShowContactDialog(true);
  };

  const handleCloseDialog = () => {
    setShowContactDialog(false);
  };

  const handleDemoBooking = async () => {
    const email = document.querySelector('input[type="text"][placeholder="Business email*"]').value;
    const phone = document.querySelector('input[type="text"][placeholder="Phone*"]').value;
    const firstName = document.querySelector('input[type="text"][placeholder="First name*"]').value;
    const lastName = document.querySelector('input[type="text"][placeholder="Last name*"]').value;
    const message = document.querySelector('textarea').value;
  
    // Prepare data to be saved in Firestore
    const contactData = {
      email,
      phone,
      firstName,
      lastName,
      message
    };
  
    try {
      // Save data to Firestore
      await setDoc(doc(firestore, "contacts", `${firstName}-${lastName}`), contactData);
      console.log("Contact data saved successfully!");
  
      // Close the dialog box after saving data
      handleCloseDialog();
    } catch (error) {
      console.error("Error saving contact data:", error);
    }
  };
  
    
    return(
        <>
        {showContactDialog && (
        <div className="contact-dialog-overlay">
          {}
          <div className="contact-dialog">
            {}
            <div className="icon-cross-dilog">
               <div className="cross-icon" onClick={handleCloseDialog}>
               &#10005;
               </div>
              </div>
            <div className="dilog-box">
            
              <div className="dilog-content">
                <div className="dilog-head">
                  <p>
                  Curious how it works? Book a personalized demo
                  </p>
                </div>
                <div className="dilog-para">
                  <p>
                  Leave your details and one of our experts will be in touch
                  </p>
                </div>
                <div className="dilog-inputs">
                <div className="inputs-di">
                <input type="text" placeholder="Business email*" />
                <input type="text" placeholder="Phone*"/>
                </div>
                <div className="inputs-di">
                  <input type="text" placeholder="First name*" />
                  <input type="text" placeholder="Last name*"/>
                </div>
                <textarea name="" id=""  rows="4" placeholder="Write your message"></textarea>
               
              </div>
              <div className="dilog-link">
                <p>
                We value your privacy and we'll only send you relevant information. For full details, check out our
                </p>
                <Link to="/privacy"  onClick={scrollToTop} style={{color:"black"}}>
                <p style={{lineHeight:"15px"}}>privacy policy</p>
                </Link>
              </div>
              <button className="dilog-button"onClick={handleDemoBooking}>
             Send Message
              </button>
              </div>
             
            </div>
               
          </div>
        </div>
      )}
        <div className="footer-main">
            <div className="footer-icons">
                <div className="footer-logo">
                    <img src="logoo.svg" alt="" />
                </div>
                {/* <div className="footer-social">
                    <div className="social-icons">
                        <img src="gitt.svg" alt="" />
                    </div>
                    <div className="social-icons">
                        <img src="linkdinn.svg" alt="" />
                    </div>
                    <div className="social-icons">
                        <img src="tweett.svg" alt="" />
                    </div>
                </div> */}
            </div>
            <div className="footer-links">
                <div className="footer-box">
                    <p  style={{color:"rgba(0, 0, 0, 1)"}}><strong>Discover</strong></p>
                   <Link onClick={scrollToTop} to="/" style={{textDecoration:"none",color:"rgba(75, 85, 99, 1)"}}>
                   <p>Home</p>
                   </Link>
                   <Link  onClick={scrollToTop} to="/feature" style={{textDecoration:"none",color:"rgba(75, 85, 99, 1)"}}>
                   <p>Feature</p>
                   </Link>
                   <Link  onClick={scrollToTop} to="/FAQ" style={{textDecoration:"none",color:"rgba(75, 85, 99, 1)"}}>
                   <p>FAQ’s</p>
                   </Link>
                   {/* <Link   onClick={scrollToTop} to="/partner" style={{textDecoration:"none",color:"rgba(75, 85, 99, 1)"}}>
                   <p>Become partner</p>
                   </Link> */}
                </div>
                <div className="footer-box">
                    <p style={{color:"rgba(0, 0, 0, 1)"}}><strong>Products</strong></p>
                   
                   <Link  onClick={scrollToTop} to="/retailer" style={{textDecoration:"none",color:"rgba(75, 85, 99, 1)"}}>
                   <p>For retailer</p>
                   </Link>
                   <Link  onClick={scrollToTop} to="/POS" style={{textDecoration:"none",color:"rgba(75, 85, 99, 1)"}}>
                   <p>For POS</p>
                   </Link>
                   <Link  onClick={scrollToTop} to="/guide" style={{textDecoration:"none",color:"rgba(75, 85, 99, 1)"}}>
                   <p>Guide</p>
                   </Link>
                 
                </div>
                <div className="footer-box">
                    <p style={{color:"rgba(0, 0, 0, 1)"}}><strong>Company</strong></p>
                    <Link  onClick={scrollToTop} to="/about" style={{textDecoration:"none",color:"rgba(75, 85, 99, 1)"}}>
                   <p>About us</p>
                   </Link>
                   <Link  onClick={handleContactClick} style={{textDecoration:"none",color:"rgba(75, 85, 99, 1)"}}>
                   <p>Contact</p>
                   </Link>
                </div>
                <div className="footer-box">
                    <p style={{color:"rgba(0, 0, 0, 1)"}}><strong>Legal</strong></p>
                    <Link  onClick={scrollToTop} to="/T&C" style={{textDecoration:"none",color:"rgba(75, 85, 99, 1)"}}>
                   <p>T&C</p>
                   </Link>
                   <Link  onClick={scrollToTop} to="/privacy" style={{textDecoration:"none",color:"rgba(75, 85, 99, 1)"}}>
                   <p>Privacy policy</p>
                   </Link>
                </div>
            </div>
        </div>
        <hr />
        <div className="footer-right">
            <p>
            © 2023 eRexeipt. All rights reserved.
            </p>
        </div>
        </>
    )
}