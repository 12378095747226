import App from "../App";
import Application from "./app";
import Appbar from "./appbar";
import "./features.css"
import Footer from "./footer";
import Icon from "./icon";
import { css } from "@emotion/react";
import { BeatLoader } from "react-spinners";
import React, { useEffect, useState } from "react";



export default function Feature(){
    const [loading, setLoading] = useState(true);

    const list = [
        {text:"Image recognition",icon:"feature1.svg",detail:"Effortlessly capture and digitize paper receipts with cutting-edge technology"},
        {text:"Cloud storage",icon:"feature2.svg",detail:"Securely store and access your digital receipts from any device, anytime"},
        {text:"Categorization",icon:"feature3.svg",detail:"Automatically categorize receipts for easy tracking and expense management"},
        {text:"Seamless integration",icon:"feature6.svg",detail:"Integrate with popular expense tracking tools for a comprehensive financial overview"},
        {text:"Eco-friendly",icon:"feature4.svg",detail:"Contribute to sustainability through digital receipts and reducing paper waste"},
        {text:"Spending insights",icon:"feature5.svg",detail:"Gain valuable insights into your spending habits with our app's smart analytics"},
      ];
      useEffect(() => {
        const timer = setTimeout(() => {
          setLoading(false);
        }, 2000);
    
        return () => clearTimeout(timer);
      }, []);
    
      if (loading) {
        return (
          <div className="loading-spinner">
            <BeatLoader color="#36D7B7" loading={loading} size={15} />
          </div>
        );
      }
    return(
        <>
       <div className="feature-main">
        <div className="feature">
            <Appbar/>
            <div className="partners-text">
                    <div className="partner-shadow-text">
                      <div className="ppp">
                      <p>
                      What makes us 
                        </p>
                      </div>
                        <div className="partner-shadow">
                            <div className="p-shadow-text">
                                <p>better</p>
                            </div>
                        </div>
                    </div> 
                        <div className="partner-para">
                            <p>
                            When it comes to digitizing your receipts, not all solutions are created equal. See how our app stands out from the rest
                            </p>
                        </div>
                       
                </div>
           <div className="icons-flex">
           <div className="feature-icons">
                {list.map((icon,index)=>
                <div key={index} className="featuress">
                     <div className="feature-icon-img">
                      <img src={icon.icon} alt="" />
                     </div>
                     <div className="feature-icon-head">
                      <p>
                          {icon.text}
                      </p>
                     </div>
                     <div className="feature-icon-text">
                      <p>
                        {icon.detail}
                      </p>
                     </div>
                </div>
                )}
               </div>
           </div>
           <Footer/>
  
  

        </div>
       </div>
        </>
    )
}