import Appbar from "./appbar";
import Footer from "./footer";
import "./privacy.css";
import { Link } from "react-router-dom";

export default function TC(){
    const privacy = [
        {title:"Acceptance of terms", text:"By accessing or using [Company Name]'s website and services, you agree to comply with and be bound by these Terms and Conditions. If you do not agree with any part of these terms, please do not use our services"},
        {title:"Use of services", text:"You must be at least 18 years old to use our services. By using our platform, you affirm that you are of legal age. You agree to use our services for lawful purposes and in accordance with these terms"},
        {title:"User property", text:"To access certain features of our services, you may be required to create an account. You are responsible for maintaining the confidentiality of your account information and are liable for all activities that occur under your account"},
        {title:"Intellectual account", text:"All content on our website and app, including text, graphics, logos, images, and software, is the property of [Company Name] and is protected by intellectual property laws. You may not use, reproduce, or distribute our content without permission"},
        {title:"Termination of services", text:"We reserve the right to terminate or suspend your access to our services at our discretion, without prior notice, for any reason, including a breach of these Terms and Conditions"},
        {title:"Changes to terms", text:"eRexeipt may update or revise these Terms and Conditions at any time. Continued use of our services after such changes constitutes acceptance of the revised terms"},

    ]
    return(
        <>
          <div className="privacy-main">
            <div className="privacy">
                <Appbar/>
                <div className="privacy-head">
                    <div className="privacy-heading">
                        <p>
                        Terms and Conditions
                        </p>
                    </div>
                    <div className="privacy-text">
                        <p>
                        Welcome to eRexeipt! Before using our website and services, please carefully read and understand the following Terms and Conditions
                        </p>
                    </div>
                </div>
               <div className="policy-cardss">
               <div className="policy">
                    {privacy.map((privacy,index)=>
                    <div key={index} className="privacy-card">
                          <div className="privacy-card-head">
                            <p>
                            {privacy.title}
                            </p>
                          </div>
                          <div className="privacy-card-text">
                            <p>
                                {privacy.text}
                            </p>
                          </div>
                    </div>
                    )}
                </div>
               </div>
               <div className="policy-cardss">
               <div className="policy">
                   
                    <div className="privacy-card">
                          <div className="privacy-card-head">
                            <p>
                            Contact us
                            </p>
                          </div>
                          <div className="privacy-card-text">
  <p>
    If you have any questions or concerns about these Terms and Conditions, please contact us at <a href="mailto:info@erexeipts.com" style={{color:"rgba(75, 85, 99, 1)"}}>info@erexeipts.com</a>. Thank you for choosing eRexeipt. We appreciate your understanding and cooperation in adhering to these terms for a positive user experience.
  </p>
</div>

                    </div>
                
                </div>
               </div>
                      <Footer/>
            </div>
        </div>
        </>
    )
}