import Application from "./app";
import Appbar from "./appbar";
import Cont from "./cont";
import Footer from "./footer";
import Icon from "./icon";
import "./retail.css"
import React, { useEffect, useState } from "react";
import { animateScroll as scroll, Events, scrollSpy } from "react-scroll";
import { useSpring, animated, config } from "react-spring";
import { BeatLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";


export default function Retail(){
  const [loading, setLoading] = useState(true);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const naviagte = useNavigate();
  const buttonClick = () =>{
    naviagte('/register');
  }
  const [guideProps, setGuideProps] = useSpring(() => ({
    opacity: 0,
    transform: "translateX(50px)",
    config: config.molasses,
  }));
  const [guideProps2, setGuideProps2] = useSpring(() => ({
    opacity: 0,
    transform: "translateX(50px)",
    config: config.molasses,
  }));
  const [guideProps3, setGuideProps3] = useSpring(() => ({
    opacity: 0,
    transform: "translateX(50px)",
    config: config.molasses,
  }));
  const [guideProps4, setGuideProps4] = useSpring(() => ({
    opacity: 0,
    transform: "translateX(50px)",
    config: config.molasses,
  }));
  const [guideProps5, setGuideProps5] = useSpring(() => ({
    opacity: 0,
    transform: "translateX(50px)",
    config: config.molasses,
  }));
  const [guideProps6, setGuideProps6] = useSpring(() => ({
    opacity: 0,
    transform: "translateX(50px)",
    config: config.molasses,
  }));

  useEffect(() => {
    Events.scrollEvent.register("begin", function (to, element) {});

    Events.scrollEvent.register("end", function (to, element) {});

    scrollSpy.update();

    window.addEventListener("scroll", handleScroll);

    return () => {
      Events.scrollEvent.remove("begin");
      Events.scrollEvent.remove("end");
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    Events.scrollEvent.register("begin", function (to, element) {});

    Events.scrollEvent.register("end", function (to, element) {});

    scrollSpy.update();

    window.addEventListener("scroll", handleScroll2);

    return () => {
      Events.scrollEvent.remove("begin");
      Events.scrollEvent.remove("end");
      window.removeEventListener("scroll", handleScroll2);
    };
  }, []);
  useEffect(() => {
    Events.scrollEvent.register("begin", function (to, element) {});

    Events.scrollEvent.register("end", function (to, element) {});

    scrollSpy.update();

    window.addEventListener("scroll", handleScroll3);

    return () => {
      Events.scrollEvent.remove("begin");
      Events.scrollEvent.remove("end");
      window.removeEventListener("scroll", handleScroll3);
    };
  }, []);
  useEffect(() => {
    Events.scrollEvent.register("begin", function (to, element) {});

    Events.scrollEvent.register("end", function (to, element) {});

    scrollSpy.update();

    window.addEventListener("scroll", handleScroll4);

    return () => {
      Events.scrollEvent.remove("begin");
      Events.scrollEvent.remove("end");
      window.removeEventListener("scroll", handleScroll4);
    };
  }, []);
  useEffect(() => {
    Events.scrollEvent.register("begin", function (to, element) {});

    Events.scrollEvent.register("end", function (to, element) {});

    scrollSpy.update();

    window.addEventListener("scroll", handleScroll5);

    return () => {
      Events.scrollEvent.remove("begin");
      Events.scrollEvent.remove("end");
      window.removeEventListener("scroll", handleScroll5);
    };
  }, []);
  useEffect(() => {
    Events.scrollEvent.register("begin", function (to, element) {});

    Events.scrollEvent.register("end", function (to, element) {});

    scrollSpy.update();

    window.addEventListener("scroll", handleScroll6);

    return () => {
      Events.scrollEvent.remove("begin");
      Events.scrollEvent.remove("end");
      window.removeEventListener("scroll", handleScroll6);
    };
  }, []);

  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  const handleScroll = () => {
    const scrollY = window.scrollY;

    if (scrollY > 500) {
      setShowScrollButton(true);
      setGuideProps({
        opacity: 1,
        transform: "translateX(0)",
      });
    } else {
      setShowScrollButton(false);
      setGuideProps({
        opacity: 0,
        transform: "translateX(-100px)",
      });
    }
  };
  const handleScroll2 = () => {
    const scrollY = window.scrollY;

    if (scrollY > 1000) {
      setShowScrollButton(true);
      setGuideProps2({
        opacity: 1,
        transform: "translateX(0)",
      });
    } else {
      setShowScrollButton(false);
      setGuideProps2({
        opacity: 0,
        transform: "translateX(100px)",
      });
    }
  };

  const handleScroll3 = () => {
    const scrollY = window.scrollY;

    if (scrollY > 1000) {
      setShowScrollButton(true);
      setGuideProps3({
        opacity: 1,
        transform: "translateY(0)",
      });
    } else {
      setShowScrollButton(false);
      setGuideProps3({
        opacity: 0,
        transform: "translateY(-100px)",
      });
    }
  };
  const handleScroll4 = () => {
    const scrollY = window.scrollY;

    if (scrollY > 500) {
      setShowScrollButton(true);
      setGuideProps4({
        opacity: 1,
        transform: "translateX(0)",
      });
    } else {
      setShowScrollButton(false);
      setGuideProps4({
        opacity: 0,
        transform: "translateX(100px)",
      });
    }
  };
  const handleScroll5 = () => {
    const scrollY = window.scrollY;

    if (scrollY > 1500) {
      setShowScrollButton(true);
      setGuideProps5({
        opacity: 1,
        transform: "translateY(0)",
      });
    } else {
      setShowScrollButton(false);
      setGuideProps5({
        opacity: 0,
        transform: "translateY(-100px)",
      });
    }
  };
  const handleScroll6 = () => {
    const scrollY = window.scrollY;

    if (scrollY > 1500) {
      setShowScrollButton(true);
      setGuideProps6({
        opacity: 1,
        transform: "translateX(0)",
      });
    } else {
      setShowScrollButton(false);
      setGuideProps6({
        opacity: 0,
        transform: "translateX(100px)",
      });
    }
  };

      const imageUrls = ["Image1.png", "Image2.png", "Image3.png", "Image4.png", "Image5.png"];
      const cardsData = [
        { text: "Going digital means no use of paper, ink, data entry. Our digital receipt system only charges you for transactions issues via 1rexeiept", image: "image13.png", heading: "Reduced paper use" },
        { text: "Tailor individual shopper experience, send targeted promotions based on past purchases, & track popular items", image: "image14.png", heading: "Improve customer experience" },
        { text: "By going digital, you paly your part in contributing towards a greener society. Let the customer know, you care", image: "image15.png", heading: "Greener society" },
      ];
      useEffect(() => {
        const timer = setTimeout(() => {
          setLoading(false);
        }, 2000);
    
        return () => clearTimeout(timer);
      }, []);
    
      if (loading) {
        return (
          <div className="loading-spinner">
            <BeatLoader color="#36D7B7" loading={loading} size={15} />
          </div>
        );
      }
    return(
        <>
       <div className="retail-div">
     <div className="retaill">
     <Appbar/>
        <div className="retail-main">
            <div className="retail-text">
               <div className="retail-head">
               <div className="boost">
                <div className="retail-boost">
                <p>
                Boost your 
                </p>
                </div>
              <div className="shadow2">
              <div className="retail-rot2">
                    <p>retail</p>
                </div>
              </div>
               </div>
              <div className="head-text">
              <p>
             business with digital payments
               </p>
              </div>
               </div>
              <div className="para-main">
              <div className="retail-para">
                <p>
                    Our innovative platform doesn't just save you money; it transforms the way you engage with customers
                </p>
               </div>
              </div>
               <div className="retail-button">
               <div className="green">
        <button onClick={buttonClick}>
          <img src="Layer.png" alt="" />
          Go green
        </button>
       </div>
       <div className="ratings1">
        <div className="rating-pics2">
          {}
          {imageUrls.map((imageUrl, index) => (
            <div key={index} className="pics">
              <img src={imageUrl} alt={`Rating ${index + 1}`} />
            </div>
          ))}
        </div>
        <div className="starts">
      <div className="star-box1">
        <img src="stars.png" alt="" /><img src="stars.png" alt="" /><img src="stars.png" alt="" /><img src="stars.png" alt="" />
      </div>
   <div className="stars-text">
          <p>
          Trusted by 400+ customers
          </p>
        </div>
        </div>
      </div>
               </div>
            </div>
            <div className="retail-pic">
               <div className="retail-img">
                <img src="retailimg1.png" alt="" />
               </div>
            </div>
        </div>
        <div className="head">
      <div className="head-head">
        <p>
        Experience the Future with our App
        </p>
      </div>
      <div className="head-p">
        <p>
        Modernizing businesses with an enhanced in-store shopping experience
                </p>
      </div>
    </div>
    <>
      {cardsData.map((cards, index) => (
        <div key={index} className="cards-home">
          {index === 1 ? (
            <>
            <div className="retail-phonee">
            <div className="card-2">
             <animated.div style={guideProps3} className="phone-text2">
              <div className="phone-cont2">
              <div className="phone-head">
                  <p>{cards.heading}</p>
                </div>
                <div className="phone-textt">
                  <p>{cards.text}</p>
                </div>
              </div>
              </animated.div>
              <animated.div style={guideProps2} className="phone3">
                <img src={cards.image} alt="" />
              </animated.div>
             </div>
            </div>
            </>
          ) :index === 2 ?  (
            <>
            <div className="retail-phonee">
            <animated.div style={guideProps5} className="phone4">
                <img src={cards.image} alt="" />
              </animated.div>
              <animated.div style={guideProps6} className="phone-text">
               <div className="phone-cont">
               <div className="phone-head">
                  <p>{cards.heading}</p>
                </div>
                <div className="phone-textt">
                  <p>{cards.text}</p>
                </div>
               </div>
              </animated.div>
            </div>
            </>
          ):( 
            <>
               <div className="retail-phonee">
            <animated.div style={guideProps} className="phone4">
                <img src={cards.image} alt="" />
              </animated.div>
              <animated.div style={guideProps4} className="phone-text">
               <div className="phone-cont">
               <div className="phone-head">
                  <p>{cards.heading}</p>
                </div>
                <div className="phone-textt">
                  <p>{cards.text}</p>
                </div>
               </div>
              </animated.div>
            </div>
            </>
          )}
        </div>
      ))}
    </>
    <div className="retail-text2">
       <div className="text2">
        <p>
        Become a retail partner for 
        </p>
       </div>
       <div className="style-text">
      <div className="style-textt">
      <p>
         seamless transition
        </p>
      </div>
      <div className="text-img">
        <img src="arrow.png" alt="" />
      </div>
       </div>
    </div>
    <div className="green-button">
    <div className="green">
        <button onClick={buttonClick}>
          <img src="Layer.png" alt="" />
          Go green
        </button>
       </div>
    </div>
    <div className="retail-steps">
        <div className="step-img">
            <img src="image17.png" alt="" />
        </div>
        <div className="steps">
           <div className="step">
            <p className="font1">Step 1</p>
            <p><strong>Schedule a demo</strong></p>
            <p className="font2">Schedule a demo with our team member</p>
           </div>
           <div className="step">
            <p className="font1">
                Step 2
            </p>
            <p>
                <strong>Receive your payment system</strong>
            </p>
            <p className="font2">
            Once you all registered with us, we’ll send your Pyramid Scanner to you
            </p>
           </div>
           <div className="step">
            <p className="font1">Step 3</p>
            <p><strong>Plug-in & you’re done</strong></p>
            <p className="font2">Follow a few simple steps and you're ready to start saving! If you need help, our team is available 24/7</p>
           </div>
        </div>
    </div>
    <div className="head" >
      <div className="head-head">
        <p>
        Benefits you’ll enjoy
        </p>
      </div>
      <div className="head-p">
        <p>
        An Impactful Hassle-Free Paperless Receipt
                </p>
      </div>
    </div>
  <Icon/>
  <Cont/>
  <div className="retail-footer">
  <Footer/>
  </div>
     </div>
       </div>
       
        </>
    )
}