import React, { useState } from "react";
import "./sign.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";



import "firebase/auth";
import { app, auth, doc } from "../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { getDoc, getFirestore } from "firebase/firestore";
import { ToastContainer, toast } from "react-toastify";

const cardsData = [
  { name: "Jason Bell", image: "Image1.png", title: "Shopper", text: "1receipt is an innovative and out of the box idea to keep record of receipts digital. Good for environment and user friendly platform with ample of marketing opportunities”" },
  { name: "Mohmin", image: "Image2.png", title: "Shopper", text: "1receipt is an innovative and out of the box idea to keep record of receipts digital. Good for environment and user friendly platform with ample of marketing opportunities”" },
  { name: "Mohmin", image: "Image3.png", title: "Shopper", text: "1receipt is an innovative and out of the box idea to keep record of receipts digital. Good for environment and user friendly platform with ample of marketing opportunities”" },
];



export default function Signin() {
  const [activeButton, setActiveButton] = useState(1);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [email2, setEmail2] = useState("");
  const [password2, setPassword2] = useState("");
  const db = getFirestore();




  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    dots: true,
  };

  const toggleDiv = (divNumber) => {
    setActiveButton(divNumber);
  };

  const onLogin = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      const userRef = doc(db, "users", user.uid); // Assuming the UID is stored under businesses collection
      const userSnap = await getDoc(userRef);
      if (userSnap.exists()) {
        navigate("/");
        console.log(user);
      } else {
        toast.error("User not found ");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  
  const onLogin2 = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email2, password2);
      const user = userCredential.user;
      const userRef = doc(db, "businesses", user.uid); // Assuming the UID is stored under users collection
      const userSnap = await getDoc(userRef);
      if (userSnap.exists()) {
        navigate("/");
        console.log(user);
      } else {
        toast.error("User not found ");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  return (
    <>
    <ToastContainer
    
    />
      <div className="main-container">
        <div className="main">
          <div className="main2">
            <p className="text">
              Seamless <br /> Transition <br /> From Paper To <br /> Digital
            </p>
            <p className="text2">
              Helping paperless shoppers save 9 million <br /> trees a year with
              one digital receipt app.
            </p>
            <div className="card-cont">
              <center>
                <Slider {...settings}>
                  {cardsData.map((card, index) => (
                    <div key={index} className="card1">
                      <div className="card-size">
                        <div className="card-message">
                          <p className="massege">
                            {card.text}
                          </p>
                        </div>
                        <div className="ecard-titl">
                          <img src={card.image} alt={card.title} />
                          <div className="profile">
                            <h2 className="profile-name" style={{ fontWeight: "500" }}>{card.name}</h2>
                            <h3 className="profile-name">{card.title}</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </center>
            </div>
          </div>
          <div className="main3">
            <div className="Sign">
              <p className="sign-in">Sign In</p>
              <p>Welcome Back, Please Login To our account</p>
              <button
                className={`button ${activeButton === 1 ? "active" : ""}`}
                onClick={() => toggleDiv(1)}
              >
                Retailer
              </button>
              <button

                className={`rbutton ${activeButton === 2 ? "active" : ""}`}
                onClick={() => toggleDiv(2)}
              >
                Customer
              </button>

              {activeButton === 1 ? (
                <div className="div1">
                 <form onSubmit={onLogin2}>
                  <div>
                     {/* Content for the first div */}
                   <div className="fields">
                    <input type="email" placeholder="Email*" value={email2} onChange={(e) => setEmail2(e.target.value)} />
                    <input type="password" placeholder="Password" value={password2} onChange={(e) => setPassword2(e.target.value)} />
                  </div>
                  <div className="check">
                    <div className="check1">
                      <label className="custom-checkbox">
                        <input
                          type="checkbox"
                          id="checked"
                          name="checked"
                          className="custom-checkbox-input"
                        />
                        <span className="checkmark"></span>
                        Keep me logged in
                      </label>
                    </div>

                    <div className="check2">
                      <button className="forget">
                        Forgot your password?
                      </button>
                    </div>
                  </div>
                
                    <button className="Login-button"type="submit">
                      Login
                    </button>
                 
                  <div className="login-but">
                    <Link to="/Register">
                      <button className="already" >
                        Don't have an account?<strong style={{ paddingLeft: "3px" }}>Register</strong>
                      </button>
                    </Link>
                  </div>
                  </div>
                 </form>

                </div>
              ) : (
                <form onSubmit={onLogin}>
                  <div className="div2">
                    {/* Content for the second div */}
                    <div className="fields">
                      <input type="text" placeholder="Email*" value={email} onChange={(e) => setEmail(e.target.value)} />
                      <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                    </div>
                    <div className="check">
                      <div className="check1">
                        <label className="custom-checkbox">
                          <input
                            type="checkbox"
                            id="checked"
                            name="checked"
                            className="custom-checkbox-input"
                          />
                          <span className="checkmark"></span>
                          Keep me logged in
                        </label>
                      </div>
                      <div className="check2">
                        <button className="forget">
                          Forgot your password?
                        </button>
                      </div>
                    </div>

                    <button type="submit" className="Login-button">
                      Login
                    </button>
                    {error && <p className="error-message">{error}</p>}


                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
